import { makeStyles } from "@mui/styles";
import {
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  useMediaQuery,
} from "@mui/material";
import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { BiCheck } from "react-icons/bi";
import CustomButton from "../UI/CustomButton.jsx";
import CustomPopup from "../UI/CustomPopup.jsx";
import Image from "../../Assets/Images/img1.jpg";
import { addDmcaOrderDetailsAction } from "../../Store/DMCA/actions";

const useStyles = makeStyles((theme) => ({
  root: {
    "& .MuiPaper-root": {
      maxWidth: "100vw",
    },
    [theme.breakpoints.down("sm")]: {
      "& .MuiPaper-root": {
        margin: "10px",
      },
    },
  },
  upgradeModalContainer: {
    display: "flex",
  },
  upgradeRightContainer: {
    flex: 1,
    overflowY: "hidden",

    width: "450px",
    "& img": {
      objectFit: "cover",
      height: "100%",
      width: "450px",
    },
  },
  upgradeLeftContainerPadding: {
    padding: "30px",
    height: "calc(100% - 60px)",
    display: "flex",
    flexDirection: "column",
    [theme.breakpoints.down("sm")]: {
      minWidth: "unset",
      padding: "24px 14px",
      height: "calc(100% - 48px)",
    },
  },
  upgradeLeftContainer: {
    minWidth: "300px",
    maxWidth: "450px",
    flex: 1,
    "& h2": {
      ...theme.typography.heading36,
      fontWeight: 500,
      margin: 0,
      marginBottom: "20px",
      [theme.breakpoints.down("sm")]: {
        fontSize: "24px",
      },
    },
    "& p": {
      ...theme.typography.body20,
      marginTop: "9px",
      fontWeight: 500,
      lineHeight: "24px",
      margin: 0,
      [theme.breakpoints.down("sm")]: {
        fontSize: "20px",
      },
    },
    [theme.breakpoints.down("sm")]: {
      minWidth: "unset",
    },
  },

  activePlanChip: {
    padding: "5px 7px !important",
    borderRadius: "5px !important",
    backgroundColor: `${theme.palette.lightGreen} !important`,
    "& .MuiChip-label": {
      padding: "0 !important",
      fontSize: "14px !important",
      lineHeight: "16px !important",
      color: theme.palette.white,
    },
  },
  notEligblePlanChip: {
    padding: "5px 7px !important",
    borderRadius: "5px !important",
    backgroundColor: `${theme.palette.grey2} !important`,
    "& .MuiChip-label": {
      padding: "0 !important",
      fontSize: "14px !important",
      lineHeight: "16px !important",
      color: theme.palette.white,
    },
  },
  listItem: {
    padding: "5px !important",
    alignItems: "flex-start !important",
    "& .MuiTypography-root": {
      color: theme.palette.black,
    },
    "& .MuiListItemIcon-root": {
      minWidth: "20px",
      marginRight: "10px",
      marginTop: "5px",
      "& svg": {
        color: theme.palette.lightGreen,
        fontSize: "30px",
      },
    },
  },
  button: {
    marginTop: "auto !important",
  },
}));

const DMCAPlanDialog = ({ open, onClose, handleClick }) => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const isMobileScreen = useMediaQuery((theme) => theme.breakpoints.down("sm"));

  const setPayment = (e) => {
    // console.log(sessionStorage.getItem("takedown"));
    dispatch(
      addDmcaOrderDetailsAction({ order_id: e.id }, () => {
        // dispatch(
        //   addTakedownRequestAction(
        //     JSON.parse(sessionStorage.getItem("takedown"))
        //   )
        // );
      })
    );
    sessionStorage.removeItem("takedown");
  };

  useEffect(() => {
    const addSBL = () => {
      const scriptId = "fsc-api";
      const existingScript = document.getElementById(scriptId);
      if (existingScript) {
        existingScript.remove();
      }
      const storeFrontToUse =
        process.env.REACT_APP_MODE === "staging"
          ? "lotillc.test.onfastspring.com/popup-lotillc"
          : "lotillc.onfastspring.com/popup-lotillc";
      const script = document.createElement("script");

      script.type = "text/javascript";
      script.id = scriptId;
      script.src =
        "https://d1f8f9xcsvx3ha.cloudfront.net/sbl/0.9.1/fastspring-builder.js";
      script.dataset.storefront = storeFrontToUse;
      script.setAttribute("data-popup-closed", "setPayment");
      window.setPayment = setPayment;
      document.body.appendChild(script);
    };
    addSBL();
  }, []);

  return (
    <CustomPopup
      noPadding
      open={Boolean(open)}
      onClose={onClose}
      className={classes.root}
      withClose={!isMobileScreen}
      imgID="popup-close"
    >
      <div className={classes.upgradeModalContainer}>
        <div className={classes.upgradeLeftContainer}>
          <div className={classes.upgradeLeftContainerPadding}>
            <h2>DMCA Plan</h2>
            <p>
              Members of our Standard and Advanced plans can go through our
              guided DMCA process to takedown unwanted images and videos from
              the internet.
            </p>
            <List>
              <ListItem className={classes.listItem}>
                <ListItemIcon>
                  <BiCheck />
                </ListItemIcon>
                <ListItemText>
                  Discreetly manage all DMCA takedowns with an easy to use and
                  private inbox. You don’t have to give any of these companies
                  your personal email.
                </ListItemText>
              </ListItem>
              <ListItem className={classes.listItem}>
                <ListItemIcon>
                  <BiCheck />
                </ListItemIcon>
                <ListItemText>
                  With one click, request your image or video to be removed
                  across all websites.
                </ListItemText>
              </ListItem>
              <ListItem className={classes.listItem}>
                <ListItemIcon>
                  <BiCheck />
                </ListItemIcon>
                <ListItemText>
                  In-depth knowledge of what each website is looking for in a
                  successful DMCA.
                </ListItemText>
              </ListItem>
            </List>
            <CustomButton
              yellowFilled
              onClick={handleClick}
              className={classes.button}
            >
              Buy 4 Requests Per $50
            </CustomButton>
          </div>
        </div>
        {!isMobileScreen && (
          <div className={classes.upgradeRightContainer}>
            <img src={Image} referrerPolicy="no-referrer"/>
          </div>
        )}
      </div>
    </CustomPopup>
  );
};

export default DMCAPlanDialog;
