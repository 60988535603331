import React,{useState} from "react";
import { makeStyles } from "@mui/styles";
import {
  Avatar,
  Grid,
  InputAdornment,
  TextField,
  useMediaQuery,
} from "@mui/material";
import {
  MdOutlineArrowBackIos,
  MdKeyboardBackspace,
  MdSearch,
} from "react-icons/md";
import { useNavigate } from "react-router-dom";
import clsx from "clsx";
import moment from "moment/moment";
import { getInitials } from "../../Helpers/functions";

const useStyles = makeStyles((theme) => ({
  dmcaInboxContainer: {
    height: "100%",
    background: theme.palette.white,
    boxShadow: "0px 4px 20px rgba(0, 0, 0, 0.05)",
  },
  body: {
    padding: "10px",
  },
  backIcon: {
    fontSize: "25px",
    cursor: "pointer",
    [theme.breakpoints.down("sm")]: {
      fontSize: "18px",
    },
  },
  heading: {
    ...theme.typography.body24,
    fontWeight: 500,
    margin: 0,
    textAlign: "center",
    [theme.breakpoints.down("sm")]: {
      fontSize: "20px",
      lineHeight: "36px",
    },
  },
  searchBox: {
    marginTop: "5px !important",
    background: "rgba(179, 179, 179, 0.1)",
    width: "100%",
    "& input": {
      padding: "10px 14px",
      "&::placeholder": {
        fontSize: "14px",
        fontStyle: "italic",
        opacity: 1,
      },

      "&:-ms-input-placeholder": {
        fontSize: "14px",
        fontStyle: "italic",
        opacity: 1,
      },

      "&::-ms-input-placeholder": {
        fontSize: "14px",
        fontStyle: "italic",
        opacity: 1,
      },
    },
    "& .MuiOutlinedInput-notchedOutline": {
      borderColor: "transparent",
    },
  },
  blackIcon: {
    color: theme.palette.black,
    fontSize: "20px",
  },
  contentContainer: {
    padding: "10px 7px",
    display: "flex",
    alignItems: "center",
    cursor: "pointer",
  },
  contentContainerSelected: {
    background: theme.palette.grey4,
    borderRadius: "5px",
  },
  avatar: {
    fontWeight: 700,
    fontSize: "20px !important",
    lineHeight: "34px !important",
  },

  textBox: {
    marginLeft: "20px",
    flexGrow: "1",
  },
  contentHead: {
    ...theme.typography.body16,
    fontWeight: 700,
    margin: 0,
    marginBottom: "5px",
  },
  contentbody: {
    ...theme.typography.body14,
    color: theme.palette.grey2,
    margin: 0,
    display: "flex",
    "& span": {
      marginLeft: "auto",
    },
  },
  mobileHeader: {
    paddingBottom: "10px",
  },
  greenAvatar: {
    backgroundColor: `${theme.palette.lightGreen} !important`,
  },
  redAvatar: {
    backgroundColor: `${theme.palette.red2} !important`,
  },
  yellowAvatar: {
    background: `${theme.palette.yellow} !important`,
    color: `${theme.palette.black} !important`,
  },
}));

const DMCAMessageBox = ({ data, handleSelect, selectedDataKey }) => {
  const classes = useStyles();
  const navigate = useNavigate();
  const isMobileScreen = useMediaQuery((theme) => theme.breakpoints.down("sm"));
  const [filteredData,setFilteredData]=useState([...data])

  const goBack = () => {
    navigate(-1);
  };
  const handleSearch=(search)=>{
   const searchedData=data.filter(item=>item.dmca_info.domain.includes(search))
  setFilteredData([...searchedData])
  }

  return (
    <div className={classes.dmcaInboxContainer}>
      <div className={classes.body}>
        <Grid
          container
          justifyContent="space-between"
          flexDirection="row"
          alignItems="center"
          className={isMobileScreen ? classes.mobileHeader : undefined}
        >
          <Grid item xs={2}>
            {isMobileScreen ? (
              <MdOutlineArrowBackIos
                onClick={goBack}
                className={classes.backIcon}
              />
            ) : (
              <MdKeyboardBackspace
                onClick={goBack}
                className={classes.backIcon}
              />
            )}
          </Grid>
          <Grid item xs={8}>
            <h2 className={classes.heading}>Messages</h2>
          </Grid>
          <Grid item xs={2}></Grid>
        </Grid>
        <TextField
          variant="outlined"
          placeholder="Search..."
          className={classes.searchBox}
          onChange={(e)=>handleSearch(e.target.value)}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <MdSearch className={classes.blackIcon} />
              </InputAdornment>
            ),
          }}
        />
        {filteredData.map((e, key) => (
          <Content
            data={e}
            key={key}
            avatarClass={
              (key + 1) % 3 === 0
                ? classes.yellowAvatar
                : (key + 1) % 2 === 0
                ? classes.redAvatar
                : classes.greenAvatar
            }
            selected={selectedDataKey === key}
            handleSelect={() => handleSelect(e._id, key)}
          />
        ))}
      </div>
    </div>
  );
};

export default DMCAMessageBox;

/* eslint-disable */

const Content = ({ data, selected, handleSelect, avatarClass }) => {
  const classes = useStyles();

  const { dmca_info, last_email, last_email_time } = data;

  return (
    <div
      className={clsx(
        classes.contentContainer,
        selected && classes.contentContainerSelected
      )}
      onClick={handleSelect}
    >
      <Avatar className={clsx(classes.avatar, avatarClass)}>
        {getInitials(dmca_info.domain)}
      </Avatar>
      <div className={classes.textBox}>
        <h3 className={classes.contentHead}>{dmca_info.domain}</h3>
        <p className={classes.contentbody}>
          {last_email?.length > 20
            ? `${last_email.replace(/<\/?[^>]+(>|$)/g, "").slice(0, 20)}...`
            : last_email.replace(/<\/?[^>]+(>|$)/g, "")}
          <span>
            {last_email_time ? moment(last_email_time).fromNow("d") : ""}
          </span>
        </p>
      </div>
    </div>
  );
};
