import {
  Checkbox,
  Collapse,
  FormControlLabel,
  FormGroup,
  TextField,
  Typography,
  InputAdornment,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import React, { useState } from "react";
import {
  MdOutlineKeyboardArrowDown,
  MdOutlineKeyboardArrowUp,
  // MdOutlineDateRange,
  MdSearch,
} from "react-icons/md";
// import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
// import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
// import { MobileDatePicker } from "@mui/x-date-pickers";
// import moment from "moment/mom ent";
import CustomButton from "../UI/CustomButton.jsx";

const useStyles = makeStyles((theme) => ({
  filterMenuContainer: {
    padding: "20px",
    width: "350px",
    [theme.breakpoints.down("sm")]: {
      width: "300px",
    },
  },
  filtersHeading: {
    fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif !important',
    fontSize: "24px !important",
    fontWeight: "500 !important",
    lineHeight: "16px !important",
  },
  filtersHeadSection: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },
  clear: {
    color: theme.palette.yellow,
    fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif !important',
    fontSize: "16px !important",
    fontWeight: "400 !important",
    lineHeight: "16px !important",
    cursor: "pointer",
  },
  checkboxGroup: {
    marginTop: "20px",
    "& .MuiFormControlLabel-root": {
      height: "35px",
    },
  },
  collapseContainer: {
    marginTop: "20px",
  },
  collapseContainerHead: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    "& h2": {
      ...theme.typography.body16_500,
      margin: 0,
    },
    "& svg": {
      fontSize: "18px",
    },
  },
  line: {
    borderBottom: "1px solid #EEEEEE",
    marginTop: "5px",
  },
  dateLabel: {
    ...theme.typography.body14,
    margin: 0,
    marginBottom: "2px",
  },
  datePicker: {
    "& input": {
      textAlign: "right",
      fontSize: "14px",
      lineHeight: "16px",
      padding: "10px",
      fontWeight: "400 ",
    },
    "& .MuiOutlinedInput-notchedOutline": {
      border: "1px solid #DADADA",
    },
  },
  blackIcon: {
    color: theme.palette.black,
    fontSize: "18px",
  },
  dateContainer: {
    display: "flex",
    marginTop: "10px !important",
    "& div:first-child": {
      marginRight: "5px",
    },
  },
  searchBox: {
    marginTop: "10px !important",
    background: "rgba(179, 179, 179, 0.1)",
    width: "100%",
    "& input": {
      padding: "10px",
      "&::placeholder": {
        fontSize: "14px",
        fontStyle: "italic",
        opacity: 1,
      },

      "&:-ms-input-placeholder": {
        fontSize: "14px",
        fontStyle: "italic",
        opacity: 1,
      },

      "&::-ms-input-placeholder": {
        fontSize: "14px",
        fontStyle: "italic",
        opacity: 1,
      },
    },
    "& .MuiOutlinedInput-notchedOutline": {
      borderColor: "transparent",
    },
  },
  saveButton: {
    marginTop: "20px",
  },
}));

const MatchesFilter = ({
  filterOptions,
  setFilterOptions,
  websites,
  handleFilter,
  handleClear,
}) => {
  const classes = useStyles();

  const [search, setSearch] = useState("");

  return (
    <div className={classes.filterMenuContainer}>
      <div className={classes.filtersHeadSection}>
        <Typography className={classes.filtersHeading}>Filters</Typography>
        <Typography className={classes.clear} onClick={() => handleClear()}>
          Clear
        </Typography>
      </div>
      <FormGroup className={classes.checkboxGroup}>
        <FormCheckbox
          label="Images"
          checked={filterOptions.image}
          handleChange={() => {
            setFilterOptions((current) => ({
              ...current,
              image: !current.image,
            }));
          }}
        />
        <FormCheckbox
          label="Videos"
          checked={filterOptions.video}
          handleChange={() => {
            setFilterOptions((current) => ({
              ...current,
              video: !current.video,
            }));
          }}
        />
         <FormCheckbox
          label="Archived"
          checked={filterOptions.archived}
          handleChange={() => {
            setFilterOptions((current) => ({
              ...current,
              archived: !current.archived,
            }));
          }}
        />
      </FormGroup>
      {/* <CollapseContainer title="Date Found">
        <LocalizationProvider dateAdapter={AdapterMoment}>
          <div className={classes.dateContainer}>
            <div>
              <p className={classes.dateLabel}>From</p>
              <MobileDatePicker
                inputFormat="YYYY-MM-DD"
                value={data.dateFoundFrom}
                onChange={(value) =>
                  setData((current) => ({ ...current, dateFoundFrom: value }))
                }
                name="dateFoundFrom"
                renderInput={(params) => (
                  <TextField
                    {...params}
                    className={classes.datePicker}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <MdOutlineDateRange className={classes.blackIcon} />
                        </InputAdornment>
                      ),
                    }}
                  />
                )}
              />
            </div>
            <div>
              <p className={classes.dateLabel}>To</p>
              <MobileDatePicker
                inputFormat="YYYY-MM-DD"
                value={data.dateFoundTo}
                onChange={(value) =>
                  setData((current) => ({ ...current, dateFoundTo: value }))
                }
                name="dateFoundFrom"
                renderInput={(params) => (
                  <TextField
                    {...params}
                    className={classes.datePicker}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <MdOutlineDateRange className={classes.blackIcon} />
                        </InputAdornment>
                      ),
                    }}
                  />
                )}
              />
            </div>
          </div>
        </LocalizationProvider>
      </CollapseContainer>

      <CollapseContainer title="Date Uploaded">
        <LocalizationProvider dateAdapter={AdapterMoment}>
          <div className={classes.dateContainer}>
            <div>
              <p className={classes.dateLabel}>From</p>
              <MobileDatePicker
                inputFormat="YYYY-MM-DD"
                value={data.dateUploadedFrom}
                onChange={(value) =>
                  setData((current) => ({
                    ...current,
                    dateUploadedFrom: value,
                  }))
                }
                name="dateFoundFrom"
                renderInput={(params) => (
                  <TextField
                    {...params}
                    className={classes.datePicker}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <MdOutlineDateRange className={classes.blackIcon} />
                        </InputAdornment>
                      ),
                    }}
                  />
                )}
              />
            </div>
            <div>
              <p className={classes.dateLabel}>To</p>
              <MobileDatePicker
                inputFormat="YYYY-MM-DD"
                value={data.dateUploadedTo}
                onChange={(value) =>
                  setData((current) => ({ ...current, dateUploadedTo: value }))
                }
                name="dateFoundFrom"
                renderInput={(params) => (
                  <TextField
                    {...params}
                    className={classes.datePicker}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <MdOutlineDateRange className={classes.blackIcon} />
                        </InputAdornment>
                      ),
                    }}
                  />
                )}
              />
            </div>
          </div>
        </LocalizationProvider>
      </CollapseContainer> */}
      <CollapseContainer title="Website">
        <TextField
          variant="outlined"
          placeholder="Search..."
          className={classes.searchBox}
          value={search}
          onChange={(e) => setSearch(e.target.value)}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <MdSearch className={classes.blackIcon} />
              </InputAdornment>
            ),
          }}
        />
        <FormGroup className={classes.checkboxGroup}>
          {websites
            .filter((e) => e.toLowerCase().includes(search.toLowerCase()))
            .map((e, i) => (
              <FormCheckbox
                label={e}
                key={i}
                checked={filterOptions.websites.includes(e)}
                handleChange={() => {
                  if (filterOptions.websites.includes(e)) {
                    setFilterOptions((current) => ({
                      ...current,
                      websites: current.websites.filter((it) => it !== e),
                    }));
                  } else {
                    setFilterOptions((current) => ({
                      ...current,
                      websites: [...current.websites, e],
                    }));
                  }
                }}
              />
            ))}
        </FormGroup>
      </CollapseContainer>
      <div className={classes.saveButton}>
        <CustomButton yellow onClick={handleFilter}>
          Save
        </CustomButton>
      </div>
    </div>
  );
};

const CollapseContainer = ({ children, title }) => {
  const classes = useStyles();

  const [open, setOpen] = useState(true);

  const toggleOpen = () => {
    setOpen((current) => !current);
  };

  return (
    <>
      <div className={classes.collapseContainer}>
        <div className={classes.collapseContainerHead}>
          <h2>{title}</h2>
          {open ? (
            <MdOutlineKeyboardArrowUp onClick={toggleOpen} />
          ) : (
            <MdOutlineKeyboardArrowDown onClick={toggleOpen} />
          )}
        </div>
        <div className={classes.line} />
        <Collapse in={open} timeout="auto" unmountOnExit>
          {children}
        </Collapse>
      </div>
    </>
  );
};

const FormCheckbox = ({ label, checked, handleChange }) => (
  <FormControlLabel
    onChange={handleChange}
    checked={checked}
    control={
      <Checkbox
        sx={{
          "& .MuiSvgIcon-root": { fontSize: 28 },
          color: "#408266",
          "&.Mui-checked": {
            color: "#408266",
          },
        }}
      />
    }
    label={label}
  />
);

export default MatchesFilter;
