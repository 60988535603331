import { Alert, Grid, Box, TextField } from "@mui/material";
import { usePaymentInputs } from "react-payment-inputs";
import images from "react-payment-inputs/images";
import { makeStyles } from "@mui/styles";
import { useDispatch, useSelector } from "react-redux";
import clsx from "clsx";
import { v4 } from "uuid"; 
import React, { useState, useEffect } from "react";
import Cookies from "js-cookie";
import Autocomplete from "@mui/material/Autocomplete";
// import Modal from "@mui/material/Modal";
// import Icon from "../../Assets/Images/check_circle_icon.png";
import CustomButton from "../UI/CustomButton.jsx";
import { onboardStep2Action, getPromoCode } from "../../Store/Auth/actions";
import { capitalizeWords, isPasswordValid } from "../../Helpers/functions";
import CustomSpinner from "../UI/CustomSpinner.jsx";

const useStyles = makeStyles((theme) => ({
	greenBox: {
		paddingBottom: "40px",
		display: "flex",
		flexDirection: "column",
		alignItems: "center",
		width: "350px",
		fontFamily:
			"ui-sans-serif,system-ui,-apple-system,BlinkMacSystemFont,Segoe UI,Roboto,Helvetica Neue,Arial,Noto Sans,sans-serif,Apple Color Emoji,Segoe UI Emoji,Segoe UI Symbol,Noto Color Emoji !important",
		[theme.breakpoints.down("sm")]: {
			// padding: "25px  10px",
			width: "300px",
		},
		"& h1": {
			textAlign: "center",
			...theme.typography.heading28,
			fontFamily:
				"ui-sans-serif,system-ui,-apple-system,BlinkMacSystemFont,Segoe UI,Roboto,Helvetica Neue,Arial,Noto Sans,sans-serif,Apple Color Emoji,Segoe UI Emoji,Segoe UI Symbol,Noto Color Emoji !important",
			margin: 0,
			fontWeight: 700,
			[theme.breakpoints.down("lg")]: {
				fontSize: "23px",
			},
			[theme.breakpoints.down("sm")]: {
				fontSize: "27px",
			},
		},
		"& p": {
			textAlign: "center",
			margin: 0,
			marginTop: "10px",
			...theme.typography.body16,
			fontFamily:
				"ui-sans-serif,system-ui,-apple-system,BlinkMacSystemFont,Segoe UI,Roboto,Helvetica Neue,Arial,Noto Sans,sans-serif,Apple Color Emoji,Segoe UI Emoji,Segoe UI Symbol,Noto Color Emoji !important",
			color: "#737373",
			[theme.breakpoints.down("lg")]: {
				fontSize: "18px",
			},
			[theme.breakpoints.down("sm")]: {
				fontSize: "15px",
			},
		},
	},
	plansCard: {
		width: "100%",
		height: "50%",
		marginTop: "5%",
		padding: "4%",
		borderRadius: "12px",
		background: "white",
		boxShadow: "rgba(0, 0, 0, 0.16) 0px 1px 3px",
		[theme.breakpoints.down("sm")]: {
			padding: "15px",
		},
		[theme.breakpoints.down(360)]: {
			width: "90%",
		},
	},
	innerCard: {
		background: "#F5F5F5 ",
		borderRadius: "12px",
		padding: "10px",
		// position: "relative",
		cursor: "pointer",
		[theme.breakpoints.down("sm")]: {
			padding: "8px",
			margin: "4px",
		},
	},

	listPoints: {
		display: "flex",
		alignItems: "center",
		fontSize: "14px",
		gap: 5,
		color: "#737373",
	},
	nextButton: {
		...theme.typography.button,
		fontFamily:
			"ui-sans-serif,system-ui,-apple-system,BlinkMacSystemFont,Segoe UI,Roboto,Helvetica Neue,Arial,Noto Sans,sans-serif,Apple Color Emoji,Segoe UI Emoji,Segoe UI Symbol,Noto Color Emoji !important",
		color: theme.palette.white,
		background: `#528068 !important`,
		width: "100%",
		margin: "5px",
		marginTop: "10px",
		height: "auto",
		fontWeight: "500 !important",
		textTransform: "none !important",
		boxShadow: "none !important",
		"&:hover": {
			background: theme.palette.darkGreen,
		},
	},

	box2Inactive: {
		opacity: "0.29",
		"& h1": {
			color: theme.palette.darkGreen,
		},
	},
	box2: {
		position: "relative",
		display: "flex",
		alignItems: "center",
		justifyContent: "center",
		flexDirection: "column",
		marginTop: "10px",
		// padding: "50px  40px",

		"& h1": {
			fontSize: "55px",
			textAlign: "center",
			fontWeight: "700",
			width: "60%",
			margin: "0",
			[theme.breakpoints.down("lg")]: {
				width: "80%",
			},
			[theme.breakpoints.down("md")]: {
				width: "100%",
			},
		},

		"& p": {
			...theme.typography.body24,
			margin: 0,
			textAlign: "center",
			marginTop: "15px",
		},
		[theme.breakpoints.down("sm")]: {
			// padding: "50px  10px",
			"& h1": {
				fontSize: "36px",
			},
		},
	},

	totalContainer: {
		display: "flex",
		alignItems: "center",
		justifyContent: "space-between",
		width: "100%",
		"& h2": {
			...theme.typography.heading24,
			fontFamily:
				"ui-sans-serif,system-ui,-apple-system,BlinkMacSystemFont,Segoe UI,Roboto,Helvetica Neue,Arial,Noto Sans,sans-serif,Apple Color Emoji,Segoe UI Emoji,Segoe UI Symbol,Noto Color Emoji !important",

			fontSize: "16px",
			fontWeight: 700,
			[theme.breakpoints.down("lg")]: {
				fontSize: "14px",
			},
			[theme.breakpoints.down("sm")]: {
				fontSize: "16px",
			},
		},
	},
	subscribeButton: {
		background: `#528068 !important`,
		color: `${theme.palette.white} !important`,
		fontFamily:
			"ui-sans-serif,system-ui,-apple-system,BlinkMacSystemFont,Segoe UI,Roboto,Helvetica Neue,Arial,Noto Sans,sans-serif,Apple Color Emoji,Segoe UI Emoji,Segoe UI Symbol,Noto Color Emoji !important",
		width: "100%",
		height: "40px !important",
		fontWeight: "500 !important",
		fontSize: "14px !important",
		borderRadius: "6px !important",
		textTransform: "none !important",
		boxShadow: "none !important",

		"&:hover": {
			background: theme.palette.darkGreen,
		},
		[theme.breakpoints.down("md")]: {
			fontSize: "13px !important",
			height: "auto !important",
		},
		[theme.breakpoints.down("sm")]: {
			fontSize: "14px !important",
			height: "40px !important",
		},
	},
	applyButton: {
		background: `#528068 !important`,
		color: `${theme.palette.white} !important`,
		maxWidth: "100px !important",
		height: "35px !important",
		marginTop: "23px !important",
		fontWeight: "500 !important",
		fontSize: "14px !important",
		boxShadow: "none !important",
		// [theme.breakpoints.down("sm")]: {
		//   fontSize:"12px !important",
		//   marginTop: "0px !important",
		// },

		[theme.breakpoints.down("sm")]: {
			fontSize: "14px !important",
			height: "auto !important",
			marginTop: "0px !important",
			marginBottom: "10px !important",
		},
	},

	fullScreenLoaderContainer: {
		position: "fixed",
		top: "0px",
		left: "0px",
		zIndex: "10",
		height: "100vh",
		width: "100vw",
		background: theme.palette.white,
		display: "flex",
		alignItems: "center",
		justifyContent: "center",
	},
	label: {
		...theme.typography.body14,

		fontFamily:
			"ui-sans-serif,system-ui,-apple-system,BlinkMacSystemFont,Segoe UI,Roboto,Helvetica Neue,Arial,Noto Sans,sans-serif,Apple Color Emoji,Segoe UI Emoji,Segoe UI Symbol,Noto Color Emoji !important",
		fontWeight: 400,
		marginBottom: "7px",
		[theme.breakpoints.down("sm")]: {
			fontSize: "13px",
		},
	},
	noButton: {
		// marginLeft: "7px !important",
		fontSize: "14px !important",
		// height: "100px !important",
		width: "100%",
		fontFamily:
			"ui-sans-serif,system-ui,-apple-system,BlinkMacSystemFont,Segoe UI,Roboto,Helvetica Neue,Arial,Noto Sans,sans-serif,Apple Color Emoji,Segoe UI Emoji,Segoe UI Symbol,Noto Color Emoji !important",
		fontWeight: "500 !important",
		marginTop: "15px !important",
		[theme.breakpoints.down("sm")]: {
			marginTop: "15px !important",
			fontSize: "15px !important",
			marginLeft: "0px !important",
			// maxWidth: "240px !important",
			height: "40px !important",
		},
	},
	countryInput: {
		"& .MuiOutlinedInput-root": {
			padding: "0px !important",
		},
	},
}));

const Paymentform = ({ data, setData, handleNext, handleChange, setProgress, progress, planDetailsState }) => {
	const classes = useStyles();

	const [showForm, setShowForm] = useState(false);
	// const [planDetailsState, setPlanDetailsState] = useState(planDetails);

	return (
		<div className={classes.greenBox}>
			<h1>Purchase</h1>
			<p>Complete your payment to get protected by Loti instantly.</p>
			<div className={classes.plansCard}>
				<div className={classes.innerCard}>
					<Box
						sx={{
							display: "flex",
							alignItems: "center",
							color: "grey",
							fontSize: { xs: "13px", sm: "14px" },
							gap: "5px",
						}}
					>
						{/* <ShoppingBasketIcon sx={{ fontSize: { xs: "14px" } }} /> */}
						<svg
							xmlns="http://www.w3.org/2000/svg"
							width="12"
							height="12"
							viewBox="0 0 24 24"
							fill="none"
							stroke="currentColor"
							strokeWidth="2"
							strokeLinecap="round"
							strokeLinejoin="round"
							className="h-3 w-3"
						>
							<path d="M6 2 3 6v14a2 2 0 0 0 2 2h14a2 2 0 0 0 2-2V6l-3-4z"></path>
							<line x1="3" y1="6" x2="21" y2="6"></line>
							<path d="M16 10a4 4 0 0 1-8 0"></path>
						</svg>
						Your cart{" "}
					</Box>

					<Box
						sx={{
							// padding: "10px",
							marginTop: "8px",
							position: "relative",
							display: "flex",
							alignItems: "center",
							gap: "10px",
							color: "#528068",
							fontWeight: 600,
							fontSize: {
								xs: "15px",
								sm: "14px",
								md: "16px",
							},
						}}
					>
						{capitalizeWords(planDetailsState.name)}
						<Box
							sx={{
								border: "1px solid",

								borderColor: "#DCDCDC",
								padding: "2px 5px",
								color: "grey",
								borderRadius: "5px",
								fontSize: {
									xs: "12px",
									sm: "11px",
									md: "12px",
								},
							}}
							onClick={() =>
								setProgress((current) => ({
									...current,
									key: 6,
									needToShowGreatNews: false,
								}))
							}
						>
							Edit
						</Box>
						<Box
							sx={{
								position: "absolute",
								fontWeight: 600,
								right: "0px",
								fontSize: {
									xs: "15px",
									sm: "14px",
									md: "16px",
								},
								color: "#528068",
							}}
						>
							$ {parseFloat(planDetailsState.price).toFixed(2)}
						</Box>
					</Box>
					<div style={{ marginTop: "10px" }}>
						{planDetailsState.points.map((point, i) => (
							<Box
								key={i}
								className={classes.listPoints}
								sx={{
									fontSize: {
										xs: "13px",
										sm: "12px",
										md: "13px",
									},
								}}
							>
								{/* <DoneIcon color="success" sx={{ fontSize: 15 }} /> */}
								<span style={{ color: "#408266", fontSize: 15 }}>✓</span>
								{point}
							</Box>
						))}
					</div>
				</div>
				<Box
					sx={{
						textAlign: "center",
						fontSize: {
							xs: "13px",
							sm: "12px",
							md: "14px",
						},
						marginTop: "15px",
						color: "grey",
					}}
				>
					Express Checkout
				</Box>
				<CustomButton onClick={() => setShowForm(true)} className={classes.noButton}>
					Pay via card
				</CustomButton>
				{showForm && (
					<Box2
						showForm={showForm}
						data={data}
						setData={setData}
						handleNext={handleNext}
						price={planDetailsState.price}
						identifier={planDetailsState.gatewayIdentifier}
						handleChange={handleChange}
						progress={progress}
						setProgress={setProgress}
						planName={planDetailsState.name}
						planDetails={planDetailsState}
					/>
				)}
			</div>
		</div>
	);
};

const Box2 = ({ showForm, data, handleChange, price, identifier, setData,  planDetails ,setProgress}) => {
	const { loading, error: apiError, errorMessage, onboardingToken, promoCode } = useSelector((state) => state.authReducer);

	// const style = {
	// 	position: "absolute",
	// 	top: "50%",
	// 	left: "50%",
	// 	transform: "translate(-50%, -50%)",
	// 	width: 400,
	// 	bgcolor: "background.paper",
	// 	boxShadow: 24,
	// 	p: 4,
	// };

	const [userUpdated, setUserUpdated] = useState(false);
	const [fullScreenLoader] = useState(false);
	const [error, setError] = useState({
		full_name: "",
		password: "",
		country: "",
		state: "",
		street: "",
		zip_code: "",
		city: "",
		coupon: "",
		card_name: "",
	});

	const [cardNumber, setCradNumber] = useState("");
	const [expDate, setExpDate] = useState("");
	const [cvc, setCvc] = useState("");
	// const [isCardError, setCardError] = useState(false);
	const [isCouponApplied, setCouponApplied] = useState(false);
	const [selectedPromoCode, setSelectedPromoCode] = useState(undefined);
	

	const triggerScript = () => {
		// Your script
 const transactionId=v4()

		window.dataLayer = window.dataLayer || [];
		window.dataLayer.push({
			event: "purchase",
			ecommerce: {
				transaction_id: transactionId,
				value: Number(planDetails.price),
				currency: "USD",
				coupon: selectedPromoCode ? selectedPromoCode.code : "",
				items: [
					{
						item_id: "",
						item_name: planDetails.name,
						affiliation: "goloti.com",
						coupon: selectedPromoCode ? selectedPromoCode.code : "",
						discount: selectedPromoCode ? (planDetails.price * selectedPromoCode.coupon.percent_off) / 100 : 0,
						index: 1,
						item_category: "",
						price: Number(planDetails.price),
						quantity: 1,
					},
				],
			},
		});
	};

	// useEffect(() => {
	// 	console.log(seconds, congratulationsOpen);
		// const countdown = setInterval(() => {
		// 	if (seconds > 0 && congratulationsOpen) {
		// 		console.log("open");
		// 		setSeconds(seconds - 1);
		// 	}
		// }, 1000);

	// 	// Clear the interval when the component unmounts
		// return () => clearInterval(countdown);
	// }, [seconds]);

	// useEffect(() => {
	// 	console.log(seconds)
	// 	if (congratulationsOpen) {
	// 		triggerScript(); // for running the script.
	// 		if (seconds > 0) {
	// 			const countdown = setInterval(() => {
	// 				if (seconds > 0 && congratulationsOpen) {
	// 					setSeconds(seconds - 1);
	// 				}
	// 			}, 1000);
				
		
	// 		} else {
	// 				setCongratulationsOpen(false);
	// 				// localStorage.setItem("token", onboardingToken);
	// 				// window.location.href = "/search";
	// 		}
			
	// 	}
	// }, [congratulationsOpen,seconds]);

	const countries = [
		{
			value: "AC",
			label: "Ascension Island",
		},
		{
			value: "AD",
			label: "Andorra",
		},
		{
			value: "AE",
			label: "United Arab Emirates",
		},
		{
			value: "AF",
			label: "Afghanistan",
		},
		{
			value: "AG",
			label: "Antigua & Barbuda",
		},
		{
			value: "AI",
			label: "Anguilla",
		},
		{
			value: "AL",
			label: "Albania",
		},
		{
			value: "AM",
			label: "Armenia",
		},
		{
			value: "AO",
			label: "Angola",
		},
		{
			value: "AQ",
			label: "Antarctica",
		},
		{
			value: "AR",
			label: "Argentina",
		},
		{
			value: "AT",
			label: "Austria",
		},
		{
			value: "AU",
			label: "Australia",
		},
		{
			value: "AW",
			label: "Aruba",
		},
		{
			value: "AX",
			label: "Åland Islands",
		},
		{
			value: "AZ",
			label: "Azerbaijan",
		},
		{
			value: "BA",
			label: "Bosnia & Herzegovina",
		},
		{
			value: "BB",
			label: "Barbados",
		},
		{
			value: "BD",
			label: "Bangladesh",
		},
		{
			value: "BE",
			label: "Belgium",
		},
		{
			value: "BF",
			label: "Burkina Faso",
		},
		{
			value: "BG",
			label: "Bulgaria",
		},
		{
			value: "BH",
			label: "Bahrain",
		},
		{
			value: "BI",
			label: "Burundi",
		},
		{
			value: "BJ",
			label: "Benin",
		},
		{
			value: "BL",
			label: "St Barthélemy",
		},
		{
			value: "BM",
			label: "Bermuda",
		},
		{
			value: "BN",
			label: "Brunei",
		},
		{
			value: "BO",
			label: "Bolivia",
		},
		{
			value: "BQ",
			label: "Caribbean Netherlands",
		},
		{
			value: "BR",
			label: "Brazil",
		},
		{
			value: "BS",
			label: "Bahamas",
		},
		{
			value: "BT",
			label: "Bhutan",
		},
		{
			value: "BV",
			label: "Bouvet Island",
		},
		{
			value: "BW",
			label: "Botswana",
		},
		{
			value: "BY",
			label: "Belarus",
		},
		{
			value: "BZ",
			label: "Belize",
		},
		{
			value: "CA",
			label: "Canada",
		},
		{
			value: "CD",
			label: "Congo - Kinshasa",
		},
		{
			value: "CF",
			label: "Central African Republic",
		},
		{
			value: "CG",
			label: "Congo - Brazzaville",
		},
		{
			value: "CH",
			label: "Switzerland",
		},
		{
			value: "CI",
			label: "Côte d’Ivoire",
		},
		{
			value: "CK",
			label: "Cook Islands",
		},
		{
			value: "CL",
			label: "Chile",
		},
		{
			value: "CM",
			label: "Cameroon",
		},
		{
			value: "CN",
			label: "China",
		},
		{
			value: "CO",
			label: "Colombia",
		},
		{
			value: "CR",
			label: "Costa Rica",
		},
		{
			value: "CV",
			label: "Cape Verde",
		},
		{
			value: "CW",
			label: "Curaçao",
		},
		{
			value: "CY",
			label: "Cyprus",
		},
		{
			value: "CZ",
			label: "Czechia",
		},
		{
			value: "DE",
			label: "Germany",
		},
		{
			value: "DJ",
			label: "Djibouti",
		},
		{
			value: "DK",
			label: "Denmark",
		},
		{
			value: "DM",
			label: "Dominica",
		},
		{
			value: "DO",
			label: "Dominican Republic",
		},
		{
			value: "DZ",
			label: "Algeria",
		},
		{
			value: "EC",
			label: "Ecuador",
		},
		{
			value: "EE",
			label: "Estonia",
		},
		{
			value: "EG",
			label: "Egypt",
		},
		{
			value: "EH",
			label: "Western Sahara",
		},
		{
			value: "ER",
			label: "Eritrea",
		},
		{
			value: "ES",
			label: "Spain",
		},
		{
			value: "ET",
			label: "Ethiopia",
		},
		{
			value: "FI",
			label: "Finland",
		},
		{
			value: "FJ",
			label: "Fiji",
		},
		{
			value: "FK",
			label: "Falkland Islands",
		},
		{
			value: "FO",
			label: "Faroe Islands",
		},
		{
			value: "FR",
			label: "France",
		},
		{
			value: "GA",
			label: "Gabon",
		},
		{
			value: "GB",
			label: "United Kingdom",
		},
		{
			value: "GD",
			label: "Grenada",
		},
		{
			value: "GE",
			label: "Georgia",
		},
		{
			value: "GF",
			label: "French Guiana",
		},
		{
			value: "GG",
			label: "Guernsey",
		},
		{
			value: "GH",
			label: "Ghana",
		},
		{
			value: "GI",
			label: "Gibraltar",
		},
		{
			value: "GL",
			label: "Greenland",
		},
		{
			value: "GM",
			label: "Gambia",
		},
		{
			value: "GN",
			label: "Guinea",
		},
		{
			value: "GP",
			label: "Guadeloupe",
		},
		{
			value: "GQ",
			label: "Equatorial Guinea",
		},
		{
			value: "GR",
			label: "Greece",
		},
		{
			value: "GS",
			label: "South Georgia & South Sandwich Islands",
		},
		{
			value: "GT",
			label: "Guatemala",
		},
		{
			value: "GU",
			label: "Guam",
		},
		{
			value: "GW",
			label: "Guinea-Bissau",
		},
		{
			value: "GY",
			label: "Guyana",
		},
		{
			value: "HK",
			label: "Hong Kong SAR China",
		},
		{
			value: "HN",
			label: "Honduras",
		},
		{
			value: "HR",
			label: "Croatia",
		},
		{
			value: "HT",
			label: "Haiti",
		},
		{
			value: "HU",
			label: "Hungary",
		},
		{
			value: "ID",
			label: "Indonesia",
		},
		{
			value: "IE",
			label: "Ireland",
		},
		{
			value: "IL",
			label: "Israel",
		},
		{
			value: "IM",
			label: "Isle of Man",
		},
		{
			value: "IN",
			label: "India",
		},
		{
			value: "IO",
			label: "British Indian Ocean Territory",
		},
		{
			value: "IQ",
			label: "Iraq",
		},
		{
			value: "IS",
			label: "Iceland",
		},
		{
			value: "IT",
			label: "Italy",
		},
		{
			value: "JE",
			label: "Jersey",
		},
		{
			value: "JM",
			label: "Jamaica",
		},
		{
			value: "JO",
			label: "Jordan",
		},
		{
			value: "JP",
			label: "Japan",
		},
		{
			value: "KE",
			label: "Kenya",
		},
		{
			value: "KG",
			label: "Kyrgyzstan",
		},
		{
			value: "KH",
			label: "Cambodia",
		},
		{
			value: "KI",
			label: "Kiribati",
		},
		{
			value: "KM",
			label: "Comoros",
		},
		{
			value: "KN",
			label: "St Kitts & Nevis",
		},
		{
			value: "KR",
			label: "South Korea",
		},
		{
			value: "KW",
			label: "Kuwait",
		},
		{
			value: "KY",
			label: "Cayman Islands",
		},
		{
			value: "KZ",
			label: "Kazakhstan",
		},
		{
			value: "LA",
			label: "Laos",
		},
		{
			value: "LB",
			label: "Lebanon",
		},
		{
			value: "LC",
			label: "St Lucia",
		},
		{
			value: "LI",
			label: "Liechtenstein",
		},
		{
			value: "LK",
			label: "Sri Lanka",
		},
		{
			value: "LR",
			label: "Liberia",
		},
		{
			value: "LS",
			label: "Lesotho",
		},
		{
			value: "LT",
			label: "Lithuania",
		},
		{
			value: "LU",
			label: "Luxembourg",
		},
		{
			value: "LV",
			label: "Latvia",
		},
		{
			value: "LY",
			label: "Libya",
		},
		{
			value: "MA",
			label: "Morocco",
		},
		{
			value: "MC",
			label: "Monaco",
		},
		{
			value: "MD",
			label: "Moldova",
		},
		{
			value: "ME",
			label: "Montenegro",
		},
		{
			value: "MF",
			label: "St Martin",
		},
		{
			value: "MG",
			label: "Madagascar",
		},
		{
			value: "MK",
			label: "North Macedonia",
		},
		{
			value: "ML",
			label: "Mali",
		},
		{
			value: "MM",
			label: "Myanmar (Burma)",
		},
		{
			value: "MN",
			label: "Mongolia",
		},
		{
			value: "MO",
			label: "Macao SAR China",
		},
		{
			value: "MQ",
			label: "Martinique",
		},
		{
			value: "MR",
			label: "Mauritania",
		},
		{
			value: "MS",
			label: "Montserrat",
		},
		{
			value: "MT",
			label: "Malta",
		},
		{
			value: "MU",
			label: "Mauritius",
		},
		{
			value: "MV",
			label: "Maldives",
		},
		{
			value: "MW",
			label: "Malawi",
		},
		{
			value: "MX",
			label: "Mexico",
		},
		{
			value: "MY",
			label: "Malaysia",
		},
		{
			value: "MZ",
			label: "Mozambique",
		},
		{
			value: "NA",
			label: "Namibia",
		},
		{
			value: "NC",
			label: "New Caledonia",
		},
		{
			value: "NE",
			label: "Niger",
		},
		{
			value: "NG",
			label: "Nigeria",
		},
		{
			value: "NI",
			label: "Nicaragua",
		},
		{
			value: "NL",
			label: "Netherlands",
		},
		{
			value: "NO",
			label: "Norway",
		},
		{
			value: "NP",
			label: "Nepal",
		},
		{
			value: "NR",
			label: "Nauru",
		},
		{
			value: "NU",
			label: "Niue",
		},
		{
			value: "NZ",
			label: "New Zealand",
		},
		{
			value: "OM",
			label: "Oman",
		},
		{
			value: "PA",
			label: "Panama",
		},
		{
			value: "PE",
			label: "Peru",
		},
		{
			value: "PF",
			label: "French Polynesia",
		},
		{
			value: "PG",
			label: "Papua New Guinea",
		},
		{
			value: "PH",
			label: "Philippines",
		},
		{
			value: "PK",
			label: "Pakistan",
		},
		{
			value: "PL",
			label: "Poland",
		},
		{
			value: "PM",
			label: "St Pierre & Miquelon",
		},
		{
			value: "PN",
			label: "Pitcairn Islands",
		},
		{
			value: "PR",
			label: "Puerto Rico",
		},
		{
			value: "PS",
			label: "Palestinian Territories",
		},
		{
			value: "PT",
			label: "Portugal",
		},
		{
			value: "PY",
			label: "Paraguay",
		},
		{
			value: "QA",
			label: "Qatar",
		},
		{
			value: "RE",
			label: "Réunion",
		},
		{
			value: "RO",
			label: "Romania",
		},
		{
			value: "RS",
			label: "Serbia",
		},
		{
			value: "RU",
			label: "Russia",
		},
		{
			value: "RW",
			label: "Rwanda",
		},
		{
			value: "SA",
			label: "Saudi Arabia",
		},
		{
			value: "SB",
			label: "Solomon Islands",
		},
		{
			value: "SC",
			label: "Seychelles",
		},
		{
			value: "SE",
			label: "Sweden",
		},
		{
			value: "SG",
			label: "Singapore",
		},
		{
			value: "SH",
			label: "St Helena",
		},
		{
			value: "SI",
			label: "Slovenia",
		},
		{
			value: "SJ",
			label: "Svalbard & Jan Mayen",
		},
		{
			value: "SK",
			label: "Slovakia",
		},
		{
			value: "SL",
			label: "Sierra Leone",
		},
		{
			value: "SM",
			label: "San Marino",
		},
		{
			value: "SN",
			label: "Senegal",
		},
		{
			value: "SO",
			label: "Somalia",
		},
		{
			value: "SR",
			label: "Suriname",
		},
		{
			value: "SS",
			label: "South Sudan",
		},
		{
			value: "ST",
			label: "São Tomé & Príncipe",
		},
		{
			value: "SV",
			label: "El Salvador",
		},
		{
			value: "SX",
			label: "Sint Maarten",
		},
		{
			value: "SZ",
			label: "Eswatini",
		},
		{
			value: "TA",
			label: "Tristan da Cunha",
		},
		{
			value: "TC",
			label: "Turks & Caicos Islands",
		},
		{
			value: "TD",
			label: "Chad",
		},
		{
			value: "TF",
			label: "French Southern Territories",
		},
		{
			value: "TG",
			label: "Togo",
		},
		{
			value: "TH",
			label: "Thailand",
		},
		{
			value: "TJ",
			label: "Tajikistan",
		},
		{
			value: "TK",
			label: "Tokelau",
		},
		{
			value: "TL",
			label: "Timor-Leste",
		},
		{
			value: "TM",
			label: "Turkmenistan",
		},
		{
			value: "TN",
			label: "Tunisia",
		},
		{
			value: "TO",
			label: "Tonga",
		},
		{
			value: "TR",
			label: "Turkey",
		},
		{
			value: "TT",
			label: "Trinidad & Tobago",
		},
		{
			value: "TV",
			label: "Tuvalu",
		},
		{
			value: "TW",
			label: "Taiwan",
		},
		{
			value: "TZ",
			label: "Tanzania",
		},
		{
			value: "UA",
			label: "Ukraine",
		},
		{
			value: "UG",
			label: "Uganda",
		},
		{
			value: "US",
			label: "United States",
		},
		{
			value: "UY",
			label: "Uruguay",
		},
		{
			value: "UZ",
			label: "Uzbekistan",
		},
		{
			value: "VA",
			label: "Vatican City",
		},
		{
			value: "VC",
			label: "St Vincent & the Grenadines",
		},
		{
			value: "VE",
			label: "Venezuela",
		},
		{
			value: "VG",
			label: "British Virgin Islands",
		},
		{
			value: "VN",
			label: "Vietnam",
		},
		{
			value: "VU",
			label: "Vanuatu",
		},
		{
			value: "WF",
			label: "Wallis & Futuna",
		},
		{
			value: "WS",
			label: "Samoa",
		},
		{
			value: "XK",
			label: "Kosovo",
		},
		{
			value: "YE",
			label: "Yemen",
		},
		{
			value: "YT",
			label: "Mayotte",
		},
		{
			value: "ZA",
			label: "South Africa",
		},
		{
			value: "ZM",
			label: "Zambia",
		},
		{
			value: "ZW",
			label: "Zimbabwe",
		},
		{
			value: "ZZ",
			label: "Unknown Region",
		},
	];

	const classes = useStyles();
	const dispatch = useDispatch();

	const { meta, getCardNumberProps, getExpiryDateProps, getCVCProps, getCardImageProps } = usePaymentInputs();

	const errorObj = {
		full_name: "",
		password: "",
		country: "",
		state: "",
		street: "",
		zip_code: "",
		city: "",
		coupon: "",
		card_name: "",
	};
	const isDataValid = () => {
		let valid = true;

		if (!data.country || data.country === "") {
			valid = false;
			errorObj.country = "Country is required.";
		}

		if (!data.password || data.password === "") {
			valid = false;
			errorObj.password = "Password is required.";
		}
		if (!data.card_name || data.card_name === "") {
			valid = false;
			errorObj.card_name = "Name on card is required.";
		}

		if (!isPasswordValid(data.password)) {
			valid = false;
			errorObj.password = "Password must be 8 characters.";
		}

		if (!cardNumber) {
			valid = false;
			errorObj.cardNumber = "Card number is required.";
		}
		if (!expDate) {
			valid = false;
			errorObj.expDate = "Expiry is required.";
		}
		if (!cvc) {
			valid = false;
			errorObj.cvc = "CVC is required.";
		}

		if (meta.error) {
			valid = false;
			if (meta.error.includes("CVC")) {
				errorObj.cvc = meta.error;
			} else if (meta.error.includes("date") || meta.error.includes("year")) {
				errorObj.expDate = meta.error;
			} else {
				errorObj.cardNumber = meta.error;
			}
		}

		if (data?.coupon && !selectedPromoCode) {
			// for promocode error
			valid = false;
			errorObj.coupon = "Invalid Coupon";
		}

		// if (isCardError) {
		//   valid = false;
		// }
		setError(errorObj);
		return valid;
	};

	const onApplyCoupon = () => {
		// for applying coupons
		if (isCouponApplied) {
			setData({ ...data, coupon: "" });
			setCouponApplied(!isCouponApplied);
			setSelectedPromoCode(undefined);
		} else if (data?.coupon) {
			dispatch(getPromoCode(onboardingToken, data.coupon));
		}
	};

	const handleSubmit = () => {
		if (isDataValid()) {
			const utmAffiliate = Cookies.get("utm_affiliate");
			if (!userUpdated) {
				dispatch(
					onboardStep2Action(
						{
							full_name: data.full_name,
							card_name: data.card_name,
							password: data.password,
							country: data.country,
							state: data.state,
							suite: data.suite,
							zip_code: data.zip_code,
							city: data.city,
							street: data.street,
							number: cardNumber,
							exp_month: expDate?.split("/")[0].replace(/\s+/g, ""),
							exp_year: expDate?.split("/")[1].replace(/\s+/g, ""),
							cvc,
							// coupon:
							//   seirenDiscount && data.coupon.toLowerCase() === "SEIREN10"
							//     ? "m20NW4gj"
							//     : undefined,
							promoCode: selectedPromoCode ? selectedPromoCode?.id : undefined,
							priceId: identifier,
							utm_affiliate: utmAffiliate,
						},
						onboardingToken,
						() => {
							setUserUpdated(true);
							triggerScript()
							setProgress((current) => ({
								...current,
								key: 8,
							}))
							// localStorage.setItem("token", onboardingToken);
							// window.location.href = "/search";
						}
					)
				);
			} else {
				// addPaymentSession();
			}
		}
	};

	useEffect(() => {
		const discount = Cookies.get("promo_code");
		if (discount) {
			setData({ ...data, coupon: discount });
			dispatch(getPromoCode(onboardingToken, discount));
		}
	}, []);

	useEffect(() => {
		if (promoCode?.id) {
			setSelectedPromoCode(promoCode);
			setCouponApplied(true);
		}
	}, [promoCode]);

	return (
		<div className={clsx(classes.box2, showForm ? "" : classes.box2Inactive)}>
			{fullScreenLoader && (
				<div className={classes.fullScreenLoaderContainer}>
					<CustomSpinner />
				</div>
			)}
			<>
				<Grid container>
					<Grid item sx={{ marginTop: "4%", width: "100%" }}>
						{/* <Modal
							open={congratulationsOpen}
							onClose={() => {}}
							aria-labelledby="modal-modal-title"
							aria-describedby="modal-modal-description"
						>
							<Box sx={style}>
								<div style={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
									<img src={Icon} alt="check circle" className={classes.icon} referrerPolicy="no-referrer" />
									<h1>Congratulations!</h1>
									<p>
										You are now subscribed to our <b>{capitalizeWords(planName)}</b>
									</p>
									<p>Redirecting you in {seconds} seconds...</p>
								</div>
							</Box>
						</Modal> */}

						<div
							style={{
								display: "flex",
								flexDirection: "column",
								width: "100%",
							}}
						>
							<label className={classes.label}>Card number</label>
							<div
								style={{
									display: "flex",
									alignItems: "center",
									gap: "5px",
									border: "1px solid rgb(222 218 218)",
									borderRadius: "5px",
								}}
							>
								<svg {...getCardImageProps({ images })} style={{ marginLeft: "5px" }} />
								<input
									{...getCardNumberProps({
										onChange: (e) => setCradNumber(e.target.value),
									})}
									value={cardNumber}
									style={{
										border: "none",
										padding: "10px",
										borderRadius: "5px",
										flexGrow: 1,
										outline: "none",
									}}
									placeholder="1234 1234 1234 1234"
								/>
							</div>
							<Box
								sx={{
									color: "red",
									marginTop: "7px",
									fontSize: {
										xs: "12px",
										sm: "11px",
										md: "12px",
									},
								}}
							>
								{error.cardNumber}
							</Box>
						</div>
						<Grid container sx={{ gap: "6%", margin: "10px 0px" }}>
							<div
								style={{
									display: "flex",
									flexDirection: "column",
									width: "47%",
								}}
							>
								<label className={classes.label}>Expiry</label>
								<input
									{...getExpiryDateProps({
										onChange: (e) => setExpDate(e.target.value),
									})}
									value={expDate}
									placeholder="MM/YY"
									style={{
										border: "1px solid rgb(222 218 218)",
										padding: "10px",
										borderRadius: "5px",
										outline: "none",
									}}
								/>
								<Box
									sx={{
										color: "red",
										marginTop: "7px",
										fontSize: {
											xs: "12px",
											sm: "11px",
											md: "12px",
										},
									}}
								>
									{error.expDate}
								</Box>
							</div>
							<div
								style={{
									display: "flex",
									flexDirection: "column",
									width: "47%",
								}}
							>
								<label className={classes.label}>CVC</label>
								<input
									{...getCVCProps({ onChange: (e) => setCvc(e.target.value) })}
									value={cvc}
									placeholder="CVC"
									style={{
										border: "1px solid rgb(222 218 218)",
										padding: "10px",
										borderRadius: "5px",
										outline: "none",
									}}
								/>
								<Box
									sx={{
										color: "red",
										marginTop: "7px",
										fontSize: {
											xs: "12px",
											sm: "11px",
											md: "12px",
										},
									}}
								>
									{error.cvc}
								</Box>
							</div>
						</Grid>

						{/* {meta.isTouched && meta.error && (         // for card  error
              <Box
                sx={{
                  color: "red",
                  fontSize: {
                    xs: "10px",
                    sm: "11px",
                    md: "11px",
                  },
                }}
              >
                {meta.error}
              </Box>
            )} */}
					</Grid>
					<Box
						sx={{
							display: "flex",
							width: "100%",
							flexDirection: { xs: "column", sm: "row" },
							gap: { xs: "0px", sm: "10px" },
						}}
					>
						<Box
							sx={{
								display: "flex",
								flexDirection: "column",
								// width: { xs: "100%", sm: "70%" },
								flexGrow: 1,
							}}
						>
							<label className={classes.label}>Name on card</label>
							<input
								label=""
								name="card_name"
								placeholder="Name on card"
								value={data.card_name}
								onChange={handleChange}
								style={{
									border: "1px solid rgb(222 218 218)",
									padding: "10px",
									borderRadius: "5px",
									outline: "none",
									margin: "0px 0px 10px 0px",
								}}
							/>
							<Box
								sx={{
									color: "red",
									fontSize: {
										xs: "12px",
										sm: "11px",
										md: "11px",
									},
								}}
							>
								{error.card_name}
							</Box>
						</Box>
					</Box>
					<Grid item xs={12}>
						<label className={classes.label}>Country</label>
						<Autocomplete
							className={classes.countryInput}
							id="combo-box-demo"
							options={countries}
							getOptionLabel={(option) => option.label}
							onChange={(e, value) => {
								setData({ ...data, country: value?.label ? value?.label : "" });
							}}
							sx={{ width: "100%", margin: "7px 0px", padding: 0 }}
							name="country"
							renderInput={(params) => (
								<TextField
									{...params}
									// variant="standard"
									label=""
									name="country"
									placeholder="Select country"
									sx={{ width: "100%", padding: "-10px" }}
								/>
							)}
						/>
						<Box
							sx={{
								color: "red",
								fontSize: {
									xs: "12px",
									sm: "11px",
									md: "11px",
								},
							}}
						>
							{error.country}
						</Box>
					</Grid>

					<Box
						sx={{
							display: "flex",
							width: "100%",
							flexDirection: { xs: "column", sm: "row" },
							gap: { xs: "0px", sm: "10px" },
							marginTop: "10px",
						}}
					>
						<Box
							sx={{
								display: "flex",
								flexDirection: "column",
								// width: { xs: "100%", sm: "70%" },
								flexGrow: 1,
							}}
						>
							<label className={classes.label}>Create Password</label>
							<input
								type="password"
								label=""
								name="password"
								value={data.password}
								onChange={handleChange}
								style={{
									border: "1px solid rgb(222 218 218)",
									padding: "10px",
									borderRadius: "5px",
									outline: "none",
									margin: "0px 0px 10px 0px",
								}}
							/>
							<Box
								sx={{
									color: "red",
									fontSize: {
										xs: "12px",
										sm: "11px",
										md: "11px",
									},
								}}
							>
								{error.password}
							</Box>
						</Box>
					</Box>

					<Box
						sx={{
							display: "flex",
							width: "100%",
							flexDirection: { xs: "column", sm: "row" },
							gap: { xs: "0px", sm: "10px" },
							marginTop: "10px",
						}}
					>
						<Box
							sx={{
								display: "flex",
								flexDirection: "column",
								// width: { xs: "100%", sm: "70%" },
								flexGrow: 1,
							}}
						>
							<label className={classes.label}>Coupon</label>
							<input
								type="text"
								label=""
								name="coupon"
								value={data.coupon}
								onChange={handleChange}
								style={{
									border: "1px solid rgb(222 218 218)",
									padding: "10px",
									borderRadius: "5px",
									outline: "none",
									margin: "0px 0px 10px 0px",
								}}
								// error={Boolean(error.coupon)}
								// helperText={
								//   isCouponApplied
								//     ? selectedPromoCode?.coupon?.name
								//     : error.coupon
								//     ? error.coupon
								//     : ""
								// }
								disabled={isCouponApplied}
							/>
							<Box
								sx={{
									color: "gray",
									fontSize: {
										xs: "12px",
										sm: "11px",
										md: "11px",
									},
								}}
							>
								{isCouponApplied ? selectedPromoCode?.coupon?.name : error.coupon ? error.coupon : ""}
							</Box>
						</Box>
						<Grid item sm={3} xs={12}>
							<CustomButton className={classes.applyButton} onClick={onApplyCoupon}>
								{isCouponApplied ? "Remove" : "Apply"}
							</CustomButton>
						</Grid>
						{/* {isCouponApplied && <Grid item xs={12}></Grid>} */}
					</Box>
				</Grid>

				<div className={classes.totalContainer}>
					<h2>Total</h2>
					<h2>
						$
						{isCouponApplied && selectedPromoCode
							? (Number(price) - (Number(price) * Number(selectedPromoCode.coupon.percent_off)) / 100).toFixed(2)
							: Number(price).toFixed(2)}
					</h2>
				</div>

				{apiError && <Alert severity="error">{errorMessage}</Alert>}
				{loading ? (
					<CustomSpinner />
				) : (
					<CustomButton
						className={classes.subscribeButton}
						onClick={handleSubmit}
						disable={loading}
						style={{ marginTop: "20px" }}
					>
						Complete payment
					</CustomButton>
				)}
			</>
		</div>
	);
};

export default Paymentform;
